import { useSelector, useDispatch } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';

import notify from '../../../utils/notify';
import { reuploadImages } from '../../../redux/boards/actions';
import * as checkoutActions from '../../../redux/checkout/actions';

import './ProcessingOrder.scss';

const CLASS = `sb-ProcessingPayment`;

const ProcessingOrder = () => {
  const [ready, setReady] = useState(false);
  const [progress, setProgress] = useState(0);
  const [didReupload, setDidReupload] = useState(false);
  const dispatch = useDispatch();

  const pushProgressTimer = useRef(null);
  const pushProgressStopAt = useRef(null);

  const {
    app,
    checkout,
    user,
    boards: { boards },
    boards: { uploaded: uploadsDone },
  } = useSelector((state) => state);

  const snapshot = {
    app,
    boards,
    user,
    checkout,
  };

  const failedTiles = boards.filter((b) => b.status === 'failed');

  const animateProgress = () => {
    setProgress((prevState) => prevState + 1);
  };

  const pushProgress = (additionalPercentage) => {
    pushProgressStopAt.current = progress + additionalPercentage;
    pushProgressTimer.current = setInterval(() => {
      setProgress((prevState) => prevState + 1);
    }, 25);
  };

  const finishOrder = () => {
    const {
      stripe,
      paypalUser,
      paypalData,
      totalPrice,
      paypalActive,
      klarnaActive,
    } = checkout;
    if (stripe || !totalPrice) {
      dispatch(checkoutActions.charge({ user, stripe }));
    } else if (paypalActive) {
      dispatch(checkoutActions.charge({ user, paypal: paypalData }));
    } else if (klarnaActive) {
      dispatch(checkoutActions.charge({ user, klarna: true }));
    }
    pushProgress(Math.floor(Math.random() * 10));
  };

  useEffect(() => {
    const animationInterval = setInterval(animateProgress, 25);
    const {
      orderPrice,
      stripeError,
      paypalActive,
      klarnaActive,
      applePayActive,
      isLoadingToken,
    } = checkout;
    const hasEmailWithCardPayment =
      !isLoadingToken && (user.email || checkout.email);
    if (!uploadsDone && !ready && !didReupload && failedTiles.length) {
      setDidReupload(true);
      reuploadImages(boards, snapshot);
    } else if (
      uploadsDone &&
      !ready &&
      (paypalActive ||
        applePayActive ||
        hasEmailWithCardPayment ||
        orderPrice === 0 ||
        klarnaActive)
    ) {
      setReady(true);
      finishOrder();
    } else if (stripeError) {
      notify({ msg: stripeError.message, error: stripeError, snapshot });
    }
    return () => {
      clearInterval(animationInterval);
    };
  }, [uploadsDone, ready, failedTiles.length]);

  useEffect(() => {
    if (progress >= pushProgressStopAt.current) {
      clearInterval(pushProgressTimer.current);
    }
  }, [progress]);

  return (
    <>
      <div className={CLASS}>
        <p className={`${CLASS}-orderStatus`}>Completing Order</p>
        <div className={`${CLASS}-successWrapper`}>
          <ProgressBar variant="info" animated now={progress} />
          <div className={`${CLASS}-successWrapper-text`}>
            Please, hold while we process your order :)
          </div>
        </div>
      </div>
      {app.footer === false ? null : (
        <footer>
          <div className={`${CLASS}-delivery`}></div>
        </footer>
      )}
    </>
  );
};

export default ProcessingOrder;
